<template>
    <div class="app-branding-color-scheme">
        <div class="title">{{ title }}</div>

        <div class="colors">
            <div>
                <verte
                    v-if="colors.first && scheme.first"

                    v-model="color_first"

                    picker="square"
                    model="hex"
                    menu-position="top"

                    ref="color_first"
                />
            </div>

            <div>
                <verte
                    v-if="colors.second && scheme.second"

                    v-model="color_second"

                    picker="square"
                    model="hex"
                    menu-position="top"

                    ref="color_second"
                />
            </div>

            <div>
                <verte
                    v-if="colors.third && scheme.third"

                    v-model="color_third"

                    picker="square"
                    model="hex"
                    menu-position="top"

                    ref="color_third"
                />
            </div>
        </div>

        <div class="actions">
            <button class="btn btn-table-action action-primary btn-copy"   @click="copy"></button>
            <button class="btn btn-table-action action-primary btn-past"   @click="past" :disabled="clipboard_empty"></button>
            <button class="btn btn-table-action action-secondary btn-undo" @click="undo" :disabled="!is_changed"></button>
        </div>
    </div>
</template>

<script>
import Verte from '@/components/verte/verte.esm.js'
import '@/components/verte/verte.css'

export default {
    props: {
        value: { type: Array, default: () => [
                   null, // default || from
                   null, // hover   || to
                   null  // active
                ] },
        original: { type: Array, default: () => [
                   null, // default || from
                   null, // hover   || to
                   null  // active
                ] },

        states: { type: Object, default: () => ({
                    default: true,
                    hover: true,
                    active: true
                }) },
                
        title:  { type: String, required: true },
        
        clipboard: { type: Object, default: () => ({}) },

        disabled: { type: Boolean, default: false },
    },

    components: {
        Verte,
    },

    data() {
        return {
            self: {
                first: false,
                second: false,
                third: false,
            },
        }
    },

    methods: {
        copy() {
            this.$emit('copy', this.colors)
        },
        past() {
            // console.group('past() {')

            if (!this.clipboard_empty) {
                let colors = {}

                for (const key in this.scheme) {
                    if (this.scheme[ key ]) {
                        colors[ key ] = key in this.clipboard ? this.clipboard[ key ] : this.colors[ key ]
                    }
                }

                this.colors = colors
            }

            // console.groupEnd()

            this.$emit('past')
        },
        undo() {
            // console.group('undo() {')

            if (this.is_changed) {
                // console.log('this.value', this.value)
                // console.log('this.original', this.original)
                
                this.$emit('input', this.original)
                this.$emit('change', this.original)

                this.self = {
                    first: true,
                    second: true,
                    third: true,
                }
            }

            // console.groupEnd()
        },

        isColorsChanged(org_colors, new_colors) {
            let is_changed = false

            if (org_colors.length == new_colors.length) {
                for (let i = 0, len = org_colors.length; i < len; i++) {
                    if (this.hexColor(org_colors[i]) != this.hexColor(new_colors[i])) {
                        is_changed = true
                        break
                    }
                }
            } else {
                is_changed = true
            }

            return is_changed
        },

        hexColor(color) {
            let hex = color ? color.replace('#', '').trim().toLowerCase() : '00000000'

            if (hex.length == 3) {
                let hex_6 = ''

                for (let i = 0; i < 3; i++) {
                    hex_6+= hex[i] + hex[i]
                }

                hex = hex_6
            }

            return hex
        },
    },

    computed: {
        colors: {
            get() {
                // console.warn('computed - colors - get', [...this.value])
                let colors = {}

                let i = 0
                for (const key in this.scheme) {
                    colors[ key ] = this.value[ i++ ] || '#000000FF'
                }

                // console.log('this.a = this.value[0] >>> ', this.value[0])

                return colors
            },

            set(value) {
                // console.warn('computed - colors - set', value)
                const colors = Object.values(value)
                this.$emit('input', colors)

                if (this.isColorsChanged(this.value, colors)) {
                    this.$emit('change', colors)
                }

                this.self = {
                    first: true,
                    second: true,
                    third: true,
                }
            },
        },

        scheme() {
            let scheme = {}

            for (const key in this.states) {
                if (this.states[ key ]) {
                    let state = null

                    switch (key) {
                        case 'from': 
                        case 'default': {
                            state = 'first'
                        } break

                        case 'to': 
                        case 'hover': {
                            state = 'second'
                        } break

                        case 'active': {
                            state = 'third'
                        } break
                    }

                    scheme[ state || key ] = this.states[ key ]
                }
            }

            return scheme
        },

        color_first: {
            get() {
                // console.log('>>> get color_first')

                // для исправления бага с "перебиванием" выбранного цвета в recent-панели колорпикера
                if (this.self.first) {
                    this.self.first = false
                } else {
                    // для исправления бага колорпикера при котором цвет выбирался только после 2го клика на палитре
                    // так как внутренняя переменная колопикера preventEcho устанавливалась в true в методе handleValue
                    // и по неизвестным причинам периодически не вызывался метод updateColor при динамической работе с компонентом колорпикера
                    this.$nextTick(() => {
                        this.$nextTick(() => {
                            this.$nextTick(() => {
                                try {
                                    // upd: вместо вызова updateColor сделал прямую утсановку preventEcho = false, так как компонент verte использует
                                    //      преобразование RGB в HSL, а затем обратно, из-за чего некоторые оттенки цветов меняются и схема считается измененой
                                    // this.$refs.color_first.$children[0].updateColor()
                                    this.$refs.color_first.$children[0].$data.preventEcho = false
                                } catch (e) {}
                            })
                        })
                    })
                }

                return this.colors.first
            },
            set(value) {
                // console.warn('set', 'color_first', value)
                if (this.self.first) {
                    this.self.first = false
                } else {
                    if (value != this.colors.first) {
                        this.colors = Object.assign({}, this.colors, { first: value })
                    }
                }
            },
        },

        color_second: {
            get() {
                // console.log('>>> get color_second')

                // для исправления бага с "перебиванием" выбранного цвета в recent-панели колорпикера
                if (this.self.second) {
                    this.self.second = false
                } else {
                    // для исправления бага колорпикера при котором цвет выбирался только после 2го клика на палитре
                    // так как внутренняя переменная колопикера preventEcho устанавливалась в true в методе handleValue
                    // и по неизвестным причинам периодически не вызывался метод updateColor при динамической работе с компонентом колорпикера
                    this.$nextTick(() => {
                        this.$nextTick(() => {
                            this.$nextTick(() => {
                                try {
                                    // upd: вместо вызова updateColor сделал прямую утсановку preventEcho = false, так как компонент verte использует
                                    //      преобразование RGB в HSL, а затем обратно, из-за чего некоторые оттенки цветов меняются и схема считается измененой
                                    // this.$refs.color_second.$children[0].updateColor()
                                    this.$refs.color_second.$children[0].$data.preventEcho = false
                                } catch (e) {}
                            })
                        })
                    })
                }

                return this.colors.second
            },
            set(value) {
                // console.warn('set', 'color_second', value)
                if (this.self.second) {
                    this.self.second = false
                } else {
                    if (value != this.colors.second) {
                        this.colors = Object.assign({}, this.colors, { second: value })
                    }
                }
            },
        },
        
        color_third: {
            get() {
                // console.log('>>> get color_third')

                // для исправления бага с "перебиванием" выбранного цвета в recent-панели колорпикера
                if (this.self.third) {
                    this.self.third = false
                } else {
                    // для исправления бага колорпикера при котором цвет выбирался только после 2го клика на палитре
                    // так как внутренняя переменная колопикера preventEcho устанавливалась в true в методе handleValue
                    // и по неизвестным причинам периодически не вызывался метод updateColor при динамической работе с компонентом колорпикера
                    this.$nextTick(() => {
                        this.$nextTick(() => {
                            this.$nextTick(() => {
                                try {
                                    // upd: вместо вызова updateColor сделал прямую утсановку preventEcho = false, так как компонент verte использует
                                    //      преобразование RGB в HSL, а затем обратно, из-за чего некоторые оттенки цветов меняются и схема считается измененой
                                    // this.$refs.color_third.$children[0].updateColor()
                                    this.$refs.color_third.$children[0].$data.preventEcho = false
                                } catch (e) {}
                            })
                        })
                    })
                }

                return this.colors.third
            },
            set(value) {
                // console.warn('set', 'color_third', value)
                if (this.self.third) {
                    this.self.third = false
                } else {
                    if (value != this.colors.third) {
                        this.colors = Object.assign({}, this.colors, { third: value })
                    }
                }
            },
        },


        clipboard_empty() {
            return !(this.clipboard && Object.keys(this.clipboard).length)
        },

        is_changed() {
            return this.isColorsChanged(this.original, this.value)
        },
    },
}
</script>

<style lang="scss">
.app-branding-color-scheme {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    padding: 16px 16px 16px 24px;
    border-bottom: 1px solid var(--color-divider);

    .title {
        flex-grow: 1;
    }
    
    .colors,
    .actions {
        flex-shrink: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .colors {
        margin-left: 16px;

        &>div {
            width: 24px;
            height: 24px;
            margin-right: 24px;

            &:last-child { margin: 0; }

            &:empty {
                visibility: hidden;
            }

            .verte {
                width: 100%;
                height: 100%;

                .verte__guide {
                    width: 100%;
                    height: 100%;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    border: 2px solid var(--color-divider);
                    padding: 2px;
                    border-radius: 50%;
                }
            }
        }
    }

    .actions {
        margin-left: 42px;

        .btn {
            margin-right: 15px;

            &:last-child {
                margin-right: 0;
            }

            &.btn-copy { @include icon-before($icon-color-copy); }
            &.btn-past { @include icon-before($icon-color-past); }
            &.btn-undo { @include icon-before($icon-color-undo); }
        }
    }
}

@media (max-width: $tablet-size) {
    .app-branding-color-scheme {
        font-size: 14px;
    }
}

@media (max-width: $mobile-size) {
    .app-branding-color-scheme {
        position: relative;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 8px 0;

        .title {
            width: 100%;
            margin: 0 0 4px 0;
        }

        .colors {
            max-width: 50%;
            margin: 0;

            &>div {
                .verte {
                    position: static;

                    .verte__menu-origin--bottom {
                        top: 100%;
                        left: 50%;
                        transform: translate(-50%, 1px);
                    }
                }
            }
        }

        .actions {
            max-width: 50%;
            margin: 0;
        }
    }
}
</style>